import React from 'react'
import { Plan, PlanHubspot } from '@/types/types'
import { formattedPrice } from '@/helpers/formatPrice'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'

import arrow from '@/assets/images/arrow-right.svg'
import vector from '@/assets/images/vector.svg'
import tick from '@/assets/scss/tick.svg'

interface PlansHubspotProps {
  descriptionTrial: string
  handleCheckout: () => void
  paymentMethod: string
  activePlan: number
  isDiscountPrice: boolean
  handlePlan: (index: number) => void
  plans: Plan[] | PlanHubspot[]
}

export function PlansHubspot(props: PlansHubspotProps) {
  const { t } = useTranslation()

  const handleCheckout = () => {
    localStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    props.handleCheckout()
  }

  return (
    <div className="plans ">
      {props.plans.map((el, index) => {
        return (
          <div
            key={el.id}
            onClick={() => {
              props.handlePlan(index)
            }}
            className={`${el.properties.mostpopular !== 'true' && index === props.activePlan ? 'bg-white' : 'bg-lightGray'} ${index === props.activePlan ? 'active bg-white before:border-green2 after:opacity-100' : 'after:opacity-0'}  p-1 plan-item overflow-hidden mt-2  rounded-lg  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-lg before:transition-all  before:border-checkBorder before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-lg after:transition-all  after:border-green2 after:border-2 active:scale-95`}
          >
            {el.properties.mostpopular === 'true' && (
              <div
                className={`${index === props.activePlan ? 'bg-green2' : 'bg-grayE'} text-center mb-1 -mt-1 -mx-6 px-4  text-white font-bold text-md transition-all`}
              >
                {t('MOST POPULAR')}
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center pl-2">
                <div
                  className={`${index === props.activePlan ? 'bg-green2 border-green2 [&>img]:opacity-100' : 'bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder mr-4 transition-all`}
                >
                  <Image className="w-3" src={tick} alt="tick" />
                </div>
                <div>
                  <span className="block font-bold text-xl12">
                    {el.properties.name} {t('Plan')}
                  </span>
                  <span className="block text-xs4 flex">
                    <s className="text-gray9 decoration-plan">
                      {' '}
                      ${formattedPrice(parseFloat(el.properties.price) ?? 0)}
                    </s>
                    <Image className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                    <span className="font-semibold">
                      ${formattedPrice(parseFloat(el.properties.price) ?? 0)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="text-price pl-4 flex items-center justify-end text-center relative min-w-price ">
                <Image
                  className="absolute top-1/2 right-0 -translate-y-1/2 h-full"
                  src={vector}
                  alt="vector"
                />
                <span className="block py-2 px-3 relative w-full">
                  <s className=" block text-gray9 text-xs4 font-regular leading-none">
                    {' '}
                    ${formattedPrice(parseFloat(el.properties.priceperday))}
                  </s>
                  <span className="text-xl font-bold block">
                    ${formattedPrice(parseFloat(el.properties.priceperday))}
                  </span>
                  <span className="text-xs4 text-black leading-none block font-medium">
                    {t('per day')}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )
      })}
      <p className="text-xs2 leading-4 mt-3 text-center">
        30 day{' '}
        <Link className="text-green2 underline" to={'/money-back'}>
          money-back guarantee
        </Link>
      </p>
      <Button
        variant="plan"
        onClick={handleCheckout}
        className="mt-5  !p-4.5 cursor-pointer relative button-plan-animation"
      >
        {t('Get my plan')}
      </Button>
      <p className="mt-0 text-xs2  text-grayB text-center mt-6">
        {/* eslint-disable-next-line react/jsx-no-comment-textnodes,@typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {props.plans[props.activePlan]?.properties?.instruction}
      </p>
    </div>
  )
}
