import React, { useState } from 'react'
import Image from '@/components/Image/Image'
import { LocalStorageService } from '@/services/local-storage-service'

import branch1 from '@/assets/images/branch-1.svg'
import branch2 from '@/assets/images/branch-2.svg'

const setPlaceholderAgeText = (age = 25) => {
  switch (age) {
    case 25:
      return 'in their 20s'
    case 55:
      return 'aged 50+'
    case 35:
      return 'in their 30s'
    default:
      return 'in their 40s'
  }
}

export function StartHeader() {
  const [age] = useState(setPlaceholderAgeText(LocalStorageService.get('placeholderAge') as number))

  return (
    <div className="flex items-center justify-center ">
      <Image src={branch1} />

      <div className="text-center px-3">
        <p className="mb-1 text-xl4 font-bold uppercase bg-gradient-to-r from-[#129258] to-[#48E590] bg-clip-text text-transparent">
          1.2 MILLION
        </p>
        <p className="text-xl font-bold text-gray-800">women {age}</p>
      </div>

      <Image src={branch2} />
    </div>
  )
}
