import { BaseHttpServices, CustomAxiosRequestConfig } from '@/services/base-http-services'
import { getPaymentPlansApiErrorMessage } from '@/app/api/payment-plan-api/payment-plan-api-error'

interface PaymentPlanApiInterface {
  find: (type?: string) => Promise<any>
}

export class PaymentPlanApiService implements PaymentPlanApiInterface {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
    this.http.getErrorMessage = (message) => getPaymentPlansApiErrorMessage(message)
  }

  find = async (type?: string): Promise<any> => {
    const params = type
      ? ({ params: { type } } as CustomAxiosRequestConfig<any>)
      : ({} as CustomAxiosRequestConfig<any>)

    try {
      const resp = await this.http.get('/payment-plan', params)
      return resp.data
    } catch (error) {
      console.error('Error in PaymentPlanApiService.find:', error)
      throw error
    }
  }

  findPrimer = async (display?: number) => {
    const params = display
      ? ({ params: { display } } as CustomAxiosRequestConfig<any>)
      : ({} as CustomAxiosRequestConfig<any>)

    try {
      const resp = await this.http.get('/payment-plan/hubspot/list', params)
      const data = resp.data

      if (data && Array.isArray(data.items)) {
        data.items.sort((a, b) => {
          const sortA = parseInt(a.properties.sort, 10) || 0
          const sortB = parseInt(b.properties.sort, 10) || 0
          return sortA - sortB
        })
      }

      return data
    } catch (error) {
      console.error('Error in PaymentPlanApiService.find:', error)
      throw error
    }
  }
}

export const PaymentPlanApi = new PaymentPlanApiService(new BaseHttpServices())
