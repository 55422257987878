import { VISIT_TIME_HOUR } from '@/constants/variables'

const isPaymentTimeCheck = () => {
  const savedTime = localStorage.getItem('visitTime')

  if (!savedTime) return false

  const savedTimestamp = parseInt(savedTime, 10)
  const currentTime = Date.now()
  const timeDifference = currentTime - savedTimestamp

  return timeDifference < VISIT_TIME_HOUR * 60 * 1000
}

export default isPaymentTimeCheck
