import { PaymentPlan, PaymentPlanPaddle, Plan } from '@/types/types'
// import { trackPlan } from '@/helpers/facebookPixelEvents'

export const formatPlan = (plan: Plan, isDiscount45: boolean) => {
  const payProProductID = plan.payProProductID
  const couponStripe = isDiscount45
    ? plan.stripeDiscounted45CouponID
    : plan.stripeDiscounted30CouponID
  const couponPaddle = isDiscount45
    ? plan.paddleDiscounted45CouponID
    : plan.paddleDiscounted30CouponID
  const couponPayPro = isDiscount45
    ? plan.payProDiscounted45CouponID
    : plan.payProDiscounted30CouponID

  // trackPlan(resultPlan)

  return {
    name: plan.name,
    originalPrice: plan.originalPrice,
    stripeProductID: plan?.stripeProductID,
    nextProductStripeID: plan?.nextProductStripeID,
    stripeCouponID: couponStripe,
    paddlePriceID: plan?.paddlePriceID,
    nextProductPaddleID: plan?.nextProductPaddleID,
    paddleCouponID: couponPaddle,
    discountedPrice: isDiscount45 ? plan.discountedPrice45 : plan.discountedPrice30,
    payProProductID,
    payProCouponID: couponPayPro,
  } as PaymentPlan
}

export const formatPlanPaddle = (plan: PaymentPlan) => {
  return {
    paddlePriceID: plan?.paddlePriceID,
    nextProductPaddleID: plan?.nextProductPaddleID,
    paddleCouponID: plan.paddleCouponID,
  } as PaymentPlanPaddle
}
