/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Layout from '@/components/Layout'
import QuestionStep from '@/components/QuestionStep/QuestionStep'
import { Answer, Answers, Question, Step } from '@/types/types'
import { StartScreen } from '@/components/QuestionsUI/StartScreen'
import { Progress } from '../Progress/Progress'
import { Header } from '../Header/Header'
import { preCreateAccount } from '@/helpers/preCreateAccount'
import { Transition } from 'react-transition-group'
import { returnDataObject } from '@/helpers/returnDataObject'
import { getValue } from '@/helpers/getObjectValue'
import {
  trackEmail,
  trackEvent,
  trackPageView,
  trackPageViewContent,
} from '@/helpers/facebookPixelEvents'
import { getCountryCode } from '@/helpers/getCountryCode'
import { parseQuestion } from '@/lib/imageCashe'
import { StartLoading } from '../QuestionsUI/StartLoading'
import { PaywallStatus } from '../Paywall/Paywall'

interface QuizProps {
  quizFile: string
  lang: string
  mainPath: string
  nextPath: string
  startPath: string
}

export default function Quiz(props: QuizProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const [steps, setSteps] = useState<Step[]>([])
  const [questions, setQuestions] = useState<Question[]>([])
  const [stepsLength, setStepsLength] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [questionsLength, setQuestionsLength] = useState(0)
  const [startQuiz, setStartQuiz] = useState(!pathname.includes('start'))
  const [startQuizPage, setStartQuizPage] = useState(pathname.includes('start'))
  const [answers, setAnswers] = useState<Answers>(
    localStorage.getItem('answers') ? JSON.parse(localStorage.getItem('answers') ?? '') : { 0: [] },
  )
  const [paywallUrl, setPaywallUrl] = useState('')
  const { currentQuestionString } = useParams()
  const { currentStepString } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const currentQuestion = parseInt(currentQuestionString ?? '0')
  const currentStep = parseInt(currentStepString ?? '0')
  const [stepTitle, setStepTitle] = useState('')
  const [showBtnBack, setShowBtnBack] = useState(false)
  const [isLastQustionStep, setIsLastQustionStep] = useState(false)
  const [isLastStep, setIsLastStep] = useState(false)
  const [removeDelay, setRemoveDelay] = useState(true)
  const [showAnimation, setShowAnimation] = useState(true)
  const [hideHeader, setHideHeader] = useState(false)
  const [hideBtnHeader, setHideBtnHeader] = useState(false)
  const [hideBackHeader, setHideBackHeader] = useState(false)
  const [hideNav, setHideNav] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [error, setError] = useState('')
  const [clickedBackButton, setClickedBackButton] = useState(false)
  const [preConfig, setPreConfig] = useState({})
  const scrollableContainerRef = useRef<HTMLDivElement>(null)

  let transition = '300ms 0ms all'
  const defaultStyle = {
    transition,
    opacity: 0,
  }
  const nodeRef = useRef(null)
  const duration = 300
  const transitionStyles = {
    entering: {
      opacity: 1,
      top: '0px',
      position: 'relative',
      transition,
    },
    entered: {
      opacity: 1,
      top: '0px',
      position: 'relative',
      transition,
    },
    exiting: {
      opacity: 0,
      top: '60px',
      position: 'relative',
      transition: '300ms all, 0ms 300ms top',
    },
    exited: {
      opacity: 0,
      top: '60px',
      position: 'relative',
      transition: '300ms all, 0ms 300ms top',
    },
  }

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length <= 1 && obj.constructor === Object
  }

  useEffect(() => {
    const paywallStatusString = sessionStorage.getItem('paywallStatus')
    if (!paywallStatusString) return

    const paywallStatus = JSON.parse(paywallStatusString) as PaywallStatus
    const newPaywallStatus = { currentStep: 0, showModal: false, wasShowing: true }
    if (paywallStatus.currentStep === 1) {
      newPaywallStatus.currentStep = 2
      newPaywallStatus.showModal = !paywallStatus.wasShowing
      newPaywallStatus.wasShowing = true
    }
    if (paywallStatus.currentStep === 2) {
      newPaywallStatus.currentStep = 1
    }
    sessionStorage.setItem('paywallStatus', JSON.stringify(newPaywallStatus))

    navigate('/paywall/', { replace: true })
  }, [location])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.quizFile}`)
        setSteps(response?.steps)
        setStepTitle(response?.steps[currentStep].name)
        setQuestionsLength(response?.steps[0].questions?.length)
        setPaywallUrl(response?.paywall)
        setPreConfig({
          workoutType: response.workoutType,
        })
      } catch (error) {
        console.error('Error fetching quiz data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()

    const handlePopstate = () => {
      setRefresh(true)
      setShowAnimation(false)
    }

    getCountryCode().then((res) => {
      localStorage.setItem('countryCode', res ? res.country : 'US')
      localStorage.setItem('postalCode', res ? res.postal : '90071')
      localStorage.setItem('ip', res ? res.ip : '')
    })

    // if (props.startPath === '/start-2') {
    //   localStorage.setItem('quizVersion', 'false')
    // }

    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        setRefresh(false)
        setShowAnimation(true)
        setStepTitle(steps[currentStep].name)
        setQuestionsLength(steps[currentStep].questions?.length)
        setHideHeader(!!steps[currentStep].questions[currentQuestion].hideHeader)
        setHideNav(!!steps[currentStep].questions[currentQuestion].hideNav)
        setHideBtnHeader(!!steps[currentStep].questions[currentQuestion].hideBtnHeader)
        setHideBackHeader(!!steps[currentStep].questions[currentQuestion].hideBackHeader)
        setAnswers(
          localStorage.getItem('answers')
            ? JSON.parse(localStorage.getItem('answers') ?? '')
            : { 0: [] },
        )
      }, 400)
    }
  }, [refresh])

  useEffect(() => {
    if (currentQuestion === 0 && currentStep === 0) {
      transition = '300ms 0ms all'
    } else {
      transition = '300ms 300ms all'
    }
    if (currentQuestion === 0 && currentStep === 0) {
      setShowBtnBack(false)
    } else {
      setShowBtnBack(true)
    }
    currentQuestion + 1 === questionsLength
      ? setIsLastQustionStep(true)
      : setIsLastQustionStep(false)
    currentStep + 1 === stepsLength ? setIsLastStep(true) : setIsLastStep(false)
    if (steps[currentStep]?.questions) {
      setQuestionsLength(steps[currentStep].questions?.length)
    }
    if (pathname.includes('start')) {
      trackEvent('start')
    }
    if (!pathname.includes('start') && currentQuestion === 0 && currentStep === 0) {
      trackPageViewContent()
    }
    trackPageView()
  }, [currentQuestion])

  useEffect(() => {
    if (steps[currentStep]?.questions) {
      setQuestionsLength(steps[currentStep].questions?.length)
    }
    setStepsLength(steps?.length)
  }, [questions, steps])

  useEffect(() => {
    if (steps && steps[currentStep]) {
      const questions = steps[currentStep].questions

      if (isEmptyObject(answers)) {
        const newAnswers = Array.from({ length: steps?.length }, (_, index) => [
          index,
          answers?.[index] || [],
        ])

        setAnswers(Object.fromEntries(newAnswers))
        localStorage.setItem('answers', JSON.stringify(Object.fromEntries(newAnswers)))
      }
      setQuestions(questions)
    }
  }, [steps, answers, currentStep])

  useEffect(() => {
    if (isEmptyObject(answers)) {
      if (pathname !== props.nextPath) {
        navigate({ pathname: props.startPath, search: location.search })
      }
    }
  }, [answers])

  useEffect(() => {
    if (scrollableContainerRef.current) scrollableContainerRef.current.scrollTop = 0

    setStartQuizPage(pathname.includes('start'))
  }, [pathname])

  const handleHideHeader = (hideHeader: boolean) => {
    setHideHeader(hideHeader)
  }
  const handleHideBtnHeader = (hideBtnHeader: boolean) => {
    setHideBtnHeader(hideBtnHeader)
  }
  const handleHideBackHeader = (hideBackHeader: boolean) => {
    setHideBackHeader(hideBackHeader)
  }

  const handleHideNav = (hideNav: boolean) => {
    setHideNav(hideNav)
  }

  const handleNextQuestion = () => {
    setClickedBackButton(false)
    if (isLastQustionStep) {
      handleNextStep()
    } else {
      setShowAnimation(false)
      setTimeout(() => {
        const nextQuestion = currentQuestion + 1
        navigate(`${props.mainPath}${currentStep}/${nextQuestion}/`)
        setShowAnimation(true)
        localStorage.setItem('answers', JSON.stringify(answers))
      }, 400)
    }
  }

  const handleAnswer = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      if (newAnswers[currentStep][currentQuestion]) {
        const answerIndex = newAnswers[currentStep].findIndex((item) => {
          return Object.keys(item)[0] === answerKey
        })
        if (answerKey.includes('array') && answerIndex > -1) {
          const currentValue = Object.values(answer)[0][0]
          if (currentValue && !currentValue.includes('remove-')) {
            newAnswers[currentStep][answerIndex][answerKey] = [
              ...newAnswers[currentStep][answerIndex][answerKey],
              ...Object.values(answer)[0],
            ]
          } else {
            newAnswers[currentStep][answerIndex][answerKey].splice(
              newAnswers[currentStep][answerIndex][answerKey].indexOf(currentValue.slice(7)),
              1,
            )
          }
        } else {
          if (answerIndex >= 0) {
            newAnswers[currentStep][answerIndex][answerKey] = answer[answerKey]
          } else {
            newAnswers[currentStep].push(answer)
          }
        }
      } else {
        newAnswers[currentStep][currentQuestion] = answer
      }
      return newAnswers
    })
  }

  const handleRemoveAnswer = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      const answerIndex = newAnswers[currentStep].findIndex((item) => {
        return Object.keys(item)[0] === answerKey
      })
      if (answerIndex >= 0) {
        newAnswers[currentStep][answerIndex][answerKey] = []
      }
      return newAnswers
    })
  }

  const handleShowNextButton = () => {
    const objectAnswer = answers[currentStep][currentQuestion]
    if (objectAnswer) {
      const hasValues = Object.values(objectAnswer)[0]?.length
      return !hasValues
    }
    return true
  }

  const isShowNextButton = useMemo(() => {
    if (loading) return true

    const objectAnswer = answers[currentStep][currentQuestion]

    if (objectAnswer) {
      const hasValues = Object.values(objectAnswer)[0]?.length
      return !hasValues
    }
    return true
  }, [answers, currentStep, currentQuestion, loading])

  const handleNextStep = () => {
    setClickedBackButton(false)
    setShowAnimation(false)
    setTimeout(() => {
      const nextStep = currentStep + 1
      setStepTitle(steps[nextStep].name)
      setShowAnimation(true)
      navigate(`${props.mainPath}${nextStep}/0/`)
      localStorage.setItem('answers', JSON.stringify(answers))
    }, 400)
  }

  const successSubmit = (customerId: string) => {
    localStorage.setItem('answers', JSON.stringify(answers))
    localStorage.setItem('customerId', customerId)
    const endTime = new Date()
    endTime.setTime(endTime.getTime() + 10 * 60 * 1000)
    localStorage.setItem('quizResult', JSON.stringify(answers))
    sessionStorage.setItem('endTimer', endTime.toISOString())
    // localStorage.removeItem('answers')
    navigate(paywallUrl)
  }

  const handleQuizSubmit = () => {
    setLoading(true)
    setError('')
    const customerId = localStorage.getItem('customerId')
    const email = getValue('email', answers)

    const currentTime = Date.now()
    localStorage.setItem('visitTime', currentTime.toString())

    if (customerId) {
      const data = {
        email,
        _id: customerId,
      }
      preCreateAccount(data, 'PATCH')
        .then((res) => {
          if (res.body.error === '"email" must be a valid email') {
            setError('Email is not valid')
          } else {
            successSubmit(customerId)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      const data = returnDataObject(answers, preConfig)
      if (!data.profile.dailyActivity) {
        data.profile.dailyActivity = 'low'
      }
      preCreateAccount(data, 'POST')
        .then((res) => {
          if (res.body.id) {
            localStorage.setItem('customerId', res.body.id as string)
            localStorage.setItem('email', email as string)

            successSubmit(res.body.id)
          } else {
            if (res.body.error === '"email" must be a valid email') {
              setError('Email is not valid')
            } else {
              setShowNotification(true)
              setTimeout(() => {
                setShowNotification(false)
              }, 3000)
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
    trackEmail('mail_1', email as string)
  }

  const handlerBackBtn = () => {
    setClickedBackButton(true)
    setShowAnimation(false)
    setTimeout(() => {
      let newQuestion = currentQuestion
      let newStep = currentStep
      if (newQuestion !== 0) {
        newQuestion = currentQuestion - 1
      }
      if (currentQuestion === 0) {
        newStep = currentStep - 1
        newQuestion = steps[newStep].questions?.length - 1
        setQuestionsLength(steps[newStep].questions?.length)
      }
      setShowAnimation(true)
      navigate(`${props.mainPath}${newStep}/${newQuestion}/`)
    }, 400)
    setTimeout(() => {
      if (currentQuestion === 0) {
        setStepTitle(steps[currentStep - 1].name)
      }
    }, 600)
  }

  // if (loading) {
  //   return <div></div>
  // }

  const isOnStartPage = ['/', '/start', '/start/'].some((path) => location.pathname === path)

  return (
    <>
      {isOnStartPage && <StartLoading />}
      <Layout>
        <div
          className={`${showNotification ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all  fixed top-5 left-1/2 -translate-x-1/2 z-50 px-4 box-border w-image max-w-image`}
        >
          <div className="bg-green rounded-r12 text-center text-md2 leading-6 text-white font-semibold    p-1 ">
            This email is already taken <br /> <small>Try another email</small>
          </div>
        </div>
        <div
          className={`${!startQuiz ? 'opacity-100 visible' : 'opacity-0 invisible'} 'p-5' absolute w-full h-full p-4 top-0 left-0 transition-all duration-300  flex flex-col overflow-y-auto overflow-x-hidden scrolling`}
        >
          <>
            <div className="absolute top-6 right-0 w-full move-burger">
              <Header
                title=""
                onBackClick={handlerBackBtn}
                showBtn={showBtnBack}
                isLastQuestion={isLastQustionStep}
                isLastStep={isLastStep}
                hideHeader={false}
                hideBtnHeader={false}
                hideBackHeader={false}
              />
            </div>
            <StartScreen
              onStart={() => {
                setStartQuiz(true)
                setRemoveDelay(true)
                navigate(props.nextPath)
              }}
            />
          </>
        </div>
        <div
          ref={scrollableContainerRef}
          className={`${startQuiz ? 'opacity-100 visible  relative  top-0' : 'top-24 absolute opacity-0 invisible '} p-4 delay-300 transition-all duration-300 h-full flex flex-col overflow-y-auto overflow-x-hidden pb-4 max-sm:pb-20`}
        >
          <div className="relative grow shrink basis-auto flex flex-col">
            <Header
              title={stepTitle}
              onBackClick={handlerBackBtn}
              showBtn={showBtnBack}
              isLastQuestion={isLastQustionStep}
              isLastStep={isLastStep}
              hideHeader={hideHeader}
              hideBtnHeader={hideBtnHeader}
              hideBackHeader={hideBackHeader}
              hideNav={hideNav}
              step={currentStep}
            />
            <div
              className={`${refresh ? 'opacity-0' : ''} progress-absolute ${hideNav ? '-translate-y-full opacity-0 !p-0 h-0 !-mt-10' : 'translate-y-0'}`}
            >
              <div
                className={`${!hideHeader && !hideBackHeader ? 'opacity-100 visible delay-150' : 'opacity-0 invisible'} duration-150  transition-all my-5 `}
              >
                <Progress
                  questionsLength={questionsLength}
                  currentQuestion={currentQuestion}
                  stepsLength={stepsLength}
                  currentStep={currentStep}
                />
              </div>
            </div>

            <div
              className={`${refresh ? 'opacity-0' : ''} relative grow shrink basis-auto flex flex-col`}
            >
              <Transition nodeRef={nodeRef} in={showAnimation} timeout={duration}>
                {(state: string) => {
                  return (
                    <div
                      ref={nodeRef}
                      style={{
                        ...defaultStyle,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ...transitionStyles[state],
                      }}
                    >
                      {steps.length && (
                        <div className="opacity-100 top-0 relative  h-full w-full container p-0">
                          {steps?.map((el, step) => {
                            if (currentStep === step) {
                              return el.questions?.map((quest, index) => {
                                if (currentQuestion === index || currentQuestion + 1 === index) {
                                  const isActive =
                                    currentQuestion === index &&
                                    currentStep === step &&
                                    !startQuizPage
                                  return (
                                    (currentQuestion === index ||
                                      currentQuestion + 1 === index) && (
                                      <QuestionStep
                                        hideBtnHeader={handleHideBtnHeader}
                                        clickedBackButton={clickedBackButton}
                                        hideBackHeader={handleHideBackHeader}
                                        hideNav={handleHideNav}
                                        hideHeader={handleHideHeader}
                                        handleRemoveAnswer={handleRemoveAnswer}
                                        onBackClick={handlerBackBtn}
                                        onAnswer={handleAnswer}
                                        onSubmit={handleQuizSubmit}
                                        onNextQuestionClick={handleNextQuestion}
                                        onNextStepClick={handleNextStep}
                                        onShowNextButton={handleShowNextButton}
                                        isShowNextButton={isShowNextButton}
                                        key={index + step}
                                        active={isActive}
                                        step={currentStep}
                                        numberQuestion={index}
                                        answers={answers}
                                        question={quest}
                                        nextQuestion={
                                          el.questions.length > index + 1
                                            ? el.questions[index + 1]
                                            : null
                                        }
                                        questionsLength={el.questions?.length}
                                        stepsLength={stepsLength}
                                        removeDelay={removeDelay}
                                        currentQuestion={currentQuestion}
                                        error={error}
                                      />
                                    )
                                  )
                                }
                              })
                            } else if (currentStep === step - 1 && el.questions?.length) {
                              parseQuestion(el.questions[0], 1000)
                            }
                          })}
                        </div>
                      )}
                    </div>
                  )
                }}
              </Transition>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
