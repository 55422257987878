export const HOMEPAGE_PATH = process.env.REACT_APP_HOMEPAGE_PATH
export const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_URL || ''
export const API_URL = process.env.REACT_APP_API_URL || ''
export const QUIZ_VERSION = process.env.REACT_APP_QUIZ_VERSION || '1'

export const STRIPE_PUBLISH_KEY_MAIN = process.env.REACT_APP_STRIPE_PUBLISH_KEY_MAIN || ''
export const STRIPE_PUBLISH_KEY_RESERVE = process.env.REACT_APP_STRIPE_PUBLISH_KEY_RESERVE || ''

export const STRIPE_SECRET_KEY_MAIN = process.env.REACT_APP_STRIPE_SECRET_KEY_MAIN || ''
export const STRIPE_SECRET_KEY_RESERVE = process.env.REACT_APP_STRIPE_SECRET_KEY_RESERVE || ''

export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || ''
export const PRIMER_ID = process.env.REACT_APP_PRIMER_ID || ''
export const AMPLITUDE_RATE = parseInt(process.env.REACT_APP_AMPLITUDE_RATE || '1')

export const VISIT_TIME_HOUR = parseInt(process.env.REACT_APP_VISIT_TIME_HOUR || '1')
